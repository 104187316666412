.customBlock {
  padding: 0;
}

.w-fit {
  width: fit-content;
}

.customBlock .flex:first-child {
  padding: 1rem;
}
.block .odd {
  background-color: rgba(222, 222, 222, 0.5);
}
.block .even {
  background-color: white;
}
.block input {
  border-color: rgba(0, 0, 0, 0.5);
}
textarea.comment {
  background-color: #f7f7ff;
  border-color: rgba(0, 0, 0, 0);
  padding: 1rem;
  min-height: 5rem;
  resize: none;
}

.unread {
  transform: translate(0, -0.75em);
}

.homeResultBlock {
  max-height: 600px;
  overflow-y: auto;
  padding-bottom: 1rem;
}
.lineSeparator {
  border-top: solid rgba(0, 0, 0, 0.5) 1px;
}
.homeResultBlock .lineSeparator:first-child {
  border-top: none;
}


.inputSymbolEuro > div {
  position: relative;
}
.inputSymbolEuro input {
  padding-right: 18px;
}
.inputSymbolEuro > div:after {
  position: absolute;
  bottom: 5px;
  content: "€";
  right: 5px;
}
.messagesPage {
  min-height: 100vh;
  background: linear-gradient(to left, #f9f9f9 50%, #eee 50%);;
}
.bg-gray-75 {
  --bg-opacity: 1;
  background-color: #EFEFEF;
  background-color: rgba(239, 239, 239, var(--bg-opacity));
}

.innerRadio {
  width: 0.65rem;
  height: 0.65rem;
}

.react-datetime-picker--small {
  width: 6.5rem;
}

.react-datetime-picker .react-datetime-picker__wrapper {
  border: none;
  border-bottom: thin solid gray;
}

.triangle {
  display: inline-block;
  width: 10px;
  height: 10px;
  clip-path: polygon(50% 75%, 0 25%, 100% 25%);
}

.recipientsList {
  position: absolute;
  top: 17px;
  width: calc(100vw - 1rem);
  margin: 0.5rem;
  z-index: 10;
  left: -3rem;
}
@media (min-width: 1024px) {
  .recipientsList {
    left: calc(100% - 15px);
    margin: 0;
    width: initial;
  }
  .recipientsList > div {
    width: 30rem;
  }

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.border-tab::after {
  display: block;
  content: "";
  border-right: 1px solid rgba(222, 222, 222, 0.5);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.border-tab:last-child::after {
  border: none;
}

.border-tab-bottom {
  border-bottom: 1px solid rgba(222, 222, 222, 0.5);
}

.infoToolTip-hover .infoToolTip-tooltip{
  display: none;
}
.infoToolTip-hover:hover .infoToolTip-tooltip{
  display: block;
}

div.todaySeparator{
  overflow: hidden;
  position: relative;
  text-align:center;
  height:5px;
  margin-bottom:20px;
}

div.todaySeparator:before {
  content: "";
  position: absolute;
  border-bottom: 9px dashed #91c1fd;
  left: -5px;
  right: 0;
  width: 200%;
}

.history-timeline-info {
  position: absolute;
  left: 50%;
  right: 50%;
}

.history-card{
  width: calc(50% - 50px);
}
.customPopUpPos {
  top: 64px;
}
